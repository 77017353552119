import { React, useEffect, useState } from "react"
import DOMPurify from "isomorphic-dompurify"
import _isEmpty from "lodash/isEmpty"

import useIsSsr from "@/hooks/useIsSsr"
import { COMPONENT_TYPES, HOMEPAGEURL } from "@/constants/index"
import { removeAndCleanLinkConcat } from "@/utils/helper"

const BreadCrumb = props => {
  const {
    data: { appliedCssClassNames = "", items = [] },
    fullData = {},
    analyticsEvent,
    analyticsData,
  } = props
  const isHomePage = url => {
    return !url || url === HOMEPAGEURL ? "/" : url
  }

  const isSsr = useIsSsr()

  const ITEM_TYPE = {
    breadcrumbList: "http://schema.org/BreadcrumbList",
    listItem: "http://schema.org/ListItem",
  }

  const [eventPageName, setEventPageName] = useState("")
  const [shortURLs, setShortURLs] = useState([])
  const [componentGroup, setComponentGroup] = useState([])
  const [columnClassNames, setColumnClassNames] = useState("")

  useEffect(() => {
    const urls = items.map(navItem => removeAndCleanLinkConcat(navItem.url))
    setShortURLs(urls)
  }, [items])

  useEffect(() => {
    if (isSsr) return
    setEventPageName(window.eventPageType)
  }, [isSsr])
  // #ToDo Hard coding zone value once received data from json will change
  const zone = "hero banner"

  useEffect(() => {
    if (!_isEmpty(fullData)) {
      Object.keys(fullData).map(key => {
        setComponentGroup(cmpGroup => [...cmpGroup, fullData[key][":type"]])
      })
    }
  }, [fullData])

  useEffect(() => {
    if (componentGroup.length > 0) {
      if (
        componentGroup.find(a => a?.includes(COMPONENT_TYPES.BREADCRUMB)) &&
        componentGroup.find(a => a?.includes(COMPONENT_TYPES.TEASER)) &&
        !columnClassNames?.includes(
          "aem-GridColumn aem-GridColumn--default--12"
        )
      ) {
        setColumnClassNames(
          className =>
            (className += " aem-GridColumn aem-GridColumn--default--12")
        )
      }
    }
  }, [componentGroup])

  const getEventInfo = (navItem, index) => {
    let path = ""
    for (let i = 0; i < index; i++) {
      path += `${items[i].title?.toLowerCase()} > `
    }
    if (!isSsr) {
      let eventInfo = {
        clickInternalLinks: "true",
        eventAction: eventPageName + ":" + zone + ":breadcrumb",
        eventName: eventPageName + ":" + zone + ":breadcrumb",
        eventType: eventPageName,
        internalLinkName: path + navItem?.title?.toLowerCase(),
        internalLinkPosition: eventPageName,
        internalLinkType: window.storeLinkType
          ? window.storeLinkType
          : eventPageName + ":breadcrumb",
        internalLinkURL: navItem?.url
          ? (navItem.url.indexOf("http") === -1 ? window.location.origin : "") +
            removeAndCleanLinkConcat(navItem.url)
          : "n/a",
        internalLinkZoneName: eventPageName + ":" + zone,
      }
      if (analyticsData) {
        eventInfo = { ...eventInfo, ...analyticsData }
      }
      return JSON.stringify(eventInfo)
    }
  }

  const handleClick = navItem => {
    if (analyticsEvent) {
      analyticsEvent(navItem)
    }
  }

  return (
    <div
      className={`breadcrumb ${
        appliedCssClassNames ? appliedCssClassNames : ""
      }${columnClassNames}`}
    >
      {items.length > 0 ? (
        <nav id="breadcrumb" className="cmp-breadcrumb" aria-label="Breadcrumb">
          <ol
            className="cmp-breadcrumb__list"
            itemScope
            itemType={ITEM_TYPE.breadcrumbList}
          >
            {items.map((navItem, index) => {
              const isLastItem = index === items.length - 1
              const isActive = navItem.active
              const shouldRenderSlash =
                !isActive && !isLastItem && !items[index + 1].active

              return (
                <li
                  key={`breadcrumb-item-${index}`}
                  className={`cmp-breadcrumb__item`}
                  itemProp="itemListElement"
                  itemScope
                  itemType={ITEM_TYPE.listItem}
                >
                  <a
                    href={isHomePage(shortURLs[index])}
                    className="cmp-breadcrumb__item-link"
                    itemProp="item"
                    data-zone={zone}
                    aria-label={navItem.title}
                    aria-current={navItem.active ? "page" : null}
                    data-gbh-data-layer={
                      !analyticsEvent && !isSsr
                        ? getEventInfo(navItem, index)
                        : null
                    }
                    onClick={() => handleClick(navItem)}
                  >
                    <span
                      itemProp="name"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(navItem?.title),
                      }}
                    />
                  </a>
                  {shouldRenderSlash && (
                    <span className="cmp-breadcrumb-slash" aria-hidden="true" />
                  )}

                  <meta itemProp="position" content={index + 1} />
                </li>
              )
            })}
          </ol>
        </nav>
      ) : null}
    </div>
  )
}

export default BreadCrumb
