import axios from "axios"
import { apiBaseUrl, downloadFileURL, oktaTokenEndpoint } from "../constants"
import { getConfig } from "@/constants/config"

export const downloadFile = (path, accessToken) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `${apiBaseUrl}${downloadFileURL}?fileName=${path}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          responseType: "blob",
        }
      )
      resolve(response)
    } catch (error) {
      reject(error)
    }
  })
}

export const getToken = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${apiBaseUrl}${oktaTokenEndpoint}?scope=guest&channel=india`
      )
      resolve(response)
    } catch (error) {
      reject(error)
    }
  })
}
